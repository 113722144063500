<template>
  <!-- Edit Mode -->
  <event-bride-edit
    v-if="isEdit"
    :content="content"
    :inValidText="inValidText"
    :isEdit="isEdit"
    :bride="bride"
    :callback="onSave"
    @onEdit="handleChangeEditMode"
  />
  <!-- Show Mode -->
  <event-bride-show
    v-else
    :content="content"
    @onEdit="handleChangeEditMode"
    :isEdit="isEdit"
    :bride="bride"
  />
</template>

<script setup>
const props = defineProps(["content", "inValidText", "bride", "saveEventInfo"]);
const isEdit = ref(false);



const handleChangeEditMode = (_isEdit) => {
  isEdit.value = _isEdit;
};

const onSave = () => {
  isEdit.value = false;
  props.saveEventInfo();
};
</script>
