<template>
  <div
    v-if="isEdit"
    class="max-w py-3 mx-2 mb-3 bg-white border border-gray-200 rounded-lg shadow  "
  >
    <div class="flex items-center justify-between">
      <h2
        class="text-2xl font-semibold px-3 text-gray-500 pb-2 border-gray-300"
      >
        {{content?.title}}
      </h2>
      <label class="relative inline-flex items-center cursor-pointer">
        <input type="checkbox" v-model="isActive" class="sr-only peer" />
        <div
          class="mr-3 w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"
        ></div>
      </label>
    </div>

    <hr class="mb-3" />
      
    <div class="px-3 mb-2">
      <label class="text-gray-600 "
        >{{content?.locationEdit}}</label
      >

      <input
        class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-blue-500 hover:shadow "
        type="text"
        v-model="eventLocation"
      />
    </div>

    <div class="px-3 mb-2 w-full">
      <label class="text-gray-600  mt-2"
        >{{content?.province}}</label
      >

      <select
        v-model="eventProvince"
        id="preweddingPause"
        class="block w-full p-2 mb-6 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
      >
        <option
          v-for="province in props.provinceList"
          :key="province"
          :value="province"
        >
          {{ province }}
        </option>
      </select>
    </div>

    <div class="px-3 mb-2">
      <label class="text-gray-600">{{content?.eventDate}}</label>

      <VueDatepickerUi
        lang="en"
        v-model="eventDate"
        inputClass="datepicker-width-full" 
      />
    </div>
    
    <div class="px-3 mt-5">
      <label class="text-gray-600">{{content?.scheduledEvent}}</label>
      <UtilsPickerTimePicker v-model="eventTimeStart" />

      <div class="flex items-center mt-5">
        <input
          v-model="eventTime['0700']"
          id="checked-checkbox1"
          type="checkbox"
          value=""
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  "
        />
        <label
          for="checked-checkbox"
          class="ms-2 text-sm font-medium text-gray-900 "
          >{{content?.morning}}</label
        >
      </div>
      <div class="flex items-center mt-2">
        <input
          v-model="eventTime['1800']"
          id="checked-checkbox2"
          type="checkbox"
          value=""
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  "
        />
        <label
          for="checked-checkbox"
          class="ms-2 text-sm font-medium text-gray-900 "
          >{{content?.evening}}</label
        >
      </div>  
    </div>

    <hr class="my-5"/>



    <div class="px-3 mb-2">
      <p class="text-gray-600 mb-2 font-bold">{{content?.title2}}</p>
      <div class="">
        <label class="text-gray-600 ">{{content?.lang}}</label>

        <select
          v-model="eventLang"
          class="block w-full p-2 mb-6 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
        >
          <option value="th">ภาษาไทย</option>
          <option value="en">English</option>
        </select>
      </div>

      <div>
        <label class="text-gray-600 ">{{content?.showRandomWish}}</label>

        <select
          v-model="eventShowRandomWish"
          class="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
        >
          <option :value="true">เปิด</option>
          <option :value="false">ปิด</option>
        </select>

        <p class=" mt-1 text-gray-400 text-sm italic">{{content?.helperRandomText}}</p>

      </div>
    </div>

    
    <div class="flex justify-end mt-1 px-3">
      <button
        class="py-1.5 px-3 m-1 text-center bg-blue-700 border rounded-md text-white hover:bg-violet-500 hover:text-gray-100 "
        @click="onSave"
      >
        Save
      </button>
      <button
        class="py-1.5 px-3 m-1 text-center bg-red-700 border rounded-md text-white hover:bg-violet-500 hover:text-gray-100 "
        @click="toggleEditMode"
      >
        Cancel
      </button>
    </div>
  </div>
</template>
<script setup>
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";
import { containsThai } from "@/utils/validate"
import { ValidateText } from "@/interfaces/appContance"
import dayjs from "dayjs"

const props = defineProps({
  content: { type: Object, default: {}},
  isEdit: { type: Boolean, required: true },
  saveEventInfo: { type: Function, required: true },
  eventActive: { type: Boolean, required: true },
  eventLang: { type: String, required: 'th' },
  eventShowRandomWish: { type: Boolean, default: true },
  eventDate: { type: String, required: true },
  eventTime: { type: String, required: true },
  eventTimeStart: { type: String, required: true },  
  eventLocation: { type: String, required: true },
  eventProvince: { type: String, required: true },
  provinceList: { type: Array, required: true },
});

const emit = defineEmits(["onEdit", "isActived", "eventDate", "eventTime","eventTimeStart", "eventLocation", "eventProvince", "eventLang"]);

const isActive = ref(false);
const eventLang = ref("");
const eventShowRandomWish = ref(true);
const eventDate = ref("");
const eventTime = ref({});
const eventTimeStart = ref("");
const eventLocation = ref("");
const eventProvince = ref("");


watchEffect(() => {
  isActive.value = props.eventActive;
  eventLang.value = props.eventLang;
  eventShowRandomWish.value = props.eventShowRandomWish;
  eventDate.value = props.eventDate;
  eventTime.value = props.eventTime;
  eventTimeStart.value = props.eventTimeStart
  eventProvince.value = props.eventProvince
  eventLocation.value = props.eventLocation
});

const toggleEditMode = () => {
  if (!props.isEdit) {
    isActive.value = false;
    eventDate.value = "";
    eventTime.value = "";
  }
  emit("onEdit", !props.isEdit);
};

const onSave = () => {
  if(!containsThai(eventLocation.value)){
    alert(ValidateText.VALIDATE_THAI_TEXT)
    return
  }

  toggleEditMode();

  emit("isActived", isActive.value);
  emit("eventDate", dayjs(eventDate.value).format("YYYY-MM-DD"));
  emit("eventTime", eventTime.value);
  emit("eventTimeStart", eventTimeStart.value);
  emit("eventLocation", eventLocation.value);
  emit("eventProvince", eventProvince.value);
  emit("eventLang", eventLang.value);
  emit("eventShowRandomWish", eventShowRandomWish.value);
  props.saveEventInfo();
};
</script>
