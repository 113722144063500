<template>
<event-groom-edit
    v-if="isEdit"
    :content="content"
    :inValidText="inValidText"
    :isEdit="isEdit"
    :groom="groom"
    :callback="onSave"
    @onEdit="handleChangeEditMode"
/>

<event-groom-show
    v-else
    :content="content"
    @onEdit="handleChangeEditMode"
    :isEdit="isEdit"
    :groom="groom"
/>
</template>

<script setup>
const props = defineProps(["content", "inValidText", "groom", "saveEventInfo"]);
const isEdit = ref(false);

const handleChangeEditMode = (_isEdit) => {
  isEdit.value = _isEdit;
};

const onSave = () => {
  isEdit.value = false;
  props.saveEventInfo();
};
</script>

<style></style>
