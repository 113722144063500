<template>
  <div  
    class="max-w py-3 mb-3 mx-2 bg-white border border-gray-200 rounded-lg shadow "
  >
    <h2
      class="text-2xl font-semibold px-3 text-gray-600  pb-2 border-gray-300"
    >
      {{content?.title}}
    </h2>
    <hr class="mb-3" />
    <div class="px-3 mb-2">
      <label class="text-gray-600 "
        >{{content?.firstName}}</label
      >
      <input
        class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-blue-500 hover:shadow "
        type="text"
        v-model="groomRef.firstName"
        @input="filterEnglish($event,'firstName')"

      />
   
      <p v-if="error.firstName" class="error-message">{{ error.firstName }}</p>

    </div>

    <div class="px-3 mb-2">
      <label class="text-gray-600 "
        >{{content?.lastName}}</label
      >
      <input
        class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-blue-500 hover:shadow "
        type="text"
        v-model="groomRef.lastName"
        @input="filterEnglish($event,'lastName')"

      />
      <p v-if="error.lastName" class="error-message">{{ error.lastName }}</p>

    </div>

    <div class="px-3 mb-2">
      <label class="text-gray-600  mt-2"
        >{{content?.nickName}}</label
      >
      <input
        class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-blue-500 hover:shadow "
        type="text"
        v-model="groomRef.nickName"
        @input="filterEnglish($event,'nickName')"

      />
      <p v-if="error.nickName" class="error-message">{{ error.nickName }}</p>

    </div>
    <div class="flex justify-end mt-1 px-3">
      <button
        class="py-1.5 px-3 m-1 text-center bg-blue-700 border rounded-md text-white hover:bg-violet-500 hover:text-gray-100 "
        @click="onSave"
      >
        Save
      </button>
      <button
        class="py-1.5 px-3 m-1 text-center bg-red-700 border rounded-md text-white hover:bg-violet-500 hover:text-gray-100 "
        @click="toggleEditMode"
      >
        Cancel
      </button>
    </div>
  </div>
</template>
<script setup>
import { containsThai } from "@/utils/validate"
import { ValidateText } from "@/interfaces/appContance"
import _ from 'lodash'

const props = defineProps({
  content: { type: Object, default: {}},
  inValidText: { type: String, default: "กรุณากรอกข้อมูลเป็นภาษาอังกฤษเท่านั้น"},
  isEdit: { type: Boolean, required: true },
  callback: { type: Function, required: true },
  groom: { type: Object,required: true }, 
});
const error = ref({
  firstName: "",
  lastName: "",
  nickName: "",
});
const groomRef = ref({...props.groom})
const filterEnglish = (event, key) => {
    
  let value = event.target.value;
  // Allow only English letters (both uppercase and lowercase) and basic punctuation
  const englishOnly = event.target.value.replace(/[^a-zA-Z.,!? ]/g, "");
  // groomRef.value[key] = englishOnly;
  groomRef.value[key] = _.capitalize(englishOnly);


  if( value !== englishOnly ) { 
    // error.value[key] = ValidateText.VALIDATE_THAI_TEXT;
    error.value[key] = props?.inValidText;
  }else{
    error.value[key] = "";
  }
};

const emit = defineEmits(["onEdit"]);

const toggleEditMode = () => {
  emit("onEdit", !props.isEdit);
};

const onSave = ()=>{    
    if(!containsThai(groomRef.value.firstName) || !containsThai(groomRef.value.lastName) || !containsThai(groomRef.value.nickName)){
      // alert(ValidateText.VALIDATE_THAI_TEXT)
      alert(props?.inValidText)
      return
    }

    Object.assign(props.groom, groomRef.value);
    toggleEditMode()     
    props.callback()
    groomRef.value = {}
}

</script>


<style scoped>
.error-message {
  color: red;
  font-size: 12px;
}
</style>